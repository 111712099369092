/** Shelf Life: Shovelling **/

import React from "react";
import { gsap } from "gsap"
import { preloadImages, scaleFullscreen } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import Carousel from "../../components/media/carousel";
import SlideshowControllerSmall from "../../components/media/slideshow-controller-small";
import FullscreenVideo from "../../components/media/fullscreen-video";

let globalState, data, preloadData, slideshowData, autoAdvanceInterval, visibleAreaEdge;

class ShelfLifePage3 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[3];
    preloadData = globalState.assets[4];

    visibleAreaEdge = 1280 - (1280 / 3 * 2) - 35;

    slideshowData = [
      {
        file: globalState.cdnUrl + data.slide1,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide3,
        fileType: 'image',
      },
      {
        file: globalState.cdnUrl + data.slide4,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide5,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide6,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide7,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide8,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide9,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide10,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide11,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide12,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide13,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide14,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide15,
        fileType: 'image'
      }
    ]


    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
    currentSlide: 1,
    autoAdvance: 6000,
    styles: {
      top: 0,
      left: 0
    }
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //Set autoAdvance
    this.setAutoAdvance();

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
    positionText(this.refs.text2Ref, "right", "bottom", globalState.textPosition.rightBottom);
    positionText(this.refs.text3Ref, "left", "bottom", globalState.textPosition.leftBottom);

    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });
    gsap.to(this.refs.text2Ref, 0.15, { autoAlpha: 1.0 });
    gsap.to(this.refs.text3Ref, 0.15, { autoAlpha: 1.0 });

    //add page base track
    globalState.baseAudioRef.updateTrack(1, false); //remove prev page VO

    //update volume of base track
    globalState.baseAudioRef.updateVolume(2, 60);

    //Update dimensions
    this.updateDimensions();



    //Add resize listener
    window.addEventListener("resize", this.updateDimensions);



    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.credit,
      globalState.cdnUrl + preloadData.background
    ], this.preloadCallback);

    //update the carousel 
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);
  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  componentDidUpdate() {
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);
  }


  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);

    //remove event listeners
    window.removeEventListener("resize", this.updateDimensions);

    //Clear AutoAdvanceInterval
    clearInterval(autoAdvanceInterval);
  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
    this.bgVideoRef.seekVideo(74);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
      showText(this.refs.text1Ref, "right", "bottom", globalState.textPosition.rightBottom);
      showText(this.refs.text1Ref, "left", "bottom", globalState.textPosition.leftBottom);


    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
      hideText(this.refs.text1Ref, "right");
      hideText(this.refs.text1Ref, "left");
    }
  }

  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }

  updateDimensions = () => {
    console.log('updateDimensions');
    scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1280, 760);

  }


  //Set AutoAdvance
  setAutoAdvance = () => {
    if (this.state.autoAdvance) {
      autoAdvanceInterval = setInterval(() => {
        let nextSlide = this.state.currentSlide + 1;

        if (nextSlide > slideshowData.length) {
          nextSlide = 1;
        }
        this.setState({ currentSlide: nextSlide });
      }, this.state.autoAdvance);
    }
  }

  //Stop AutoAdvance
  stopAutoAdvance = () => {
    this.setState({ autoAdvance: false });
    clearInterval(autoAdvanceInterval);
  }

  //Slideshow controls
  handleNextClick = () => {
    let nextSlide = this.state.currentSlide + 1;

    if (nextSlide > slideshowData.length) {
      nextSlide = 1;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }
  handleBackClick = () => {
    let nextSlide = this.state.currentSlide - 1;

    if (nextSlide < 1) {
      nextSlide = slideshowData.length;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }


  render() {

    return (

      <>

        <link rel="prefetch" href="/shelf-life/4" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/shelf-life/4" prevLocation="/shelf-life/2"
          isTransitioning={this.isTransitioning} />
        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Shelf Life" />

          <div className="text-wrapper">
            <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="For Kim, Pine Point became the kind of hometown we see in movies, the place she could leave behind, make her triumphant return to, aspire away from." />
            </div>
            <div className="text text--right-bottom" ref="text2Ref">
              <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginRight: "0", marginBottom: "30px" }} onClick={this.toggleTextDisplay} alt="She looks back on it fondly enough, though; she was there long enough to become a good person." />
            </div>
            <div className="text text--left-bottom" ref="text3Ref">
              <input type="image" src={globalState.cdnUrl + data.text3} style={{ marginLeft: "0", marginBottom: "30px" }} onClick={this.toggleTextDisplay} alt="When I look at people’s faces in the old Pine Point photos, there’s no sense of hesitation, no hint that they knew that one day this all might end." />
            </div>
          </div>

          <SlideshowControllerSmall xPos={"calc(100vw - 485px)"} yPos={"calc(100vh - 135px)"} handleNextClick={this.handleNextClick} handleBackClick={this.handleBackClick} state={this.state} totalSlides={slideshowData.length} />

          <div className="fullscreen-bg" ref="fullscreenBgRef" >
            <div className="carousel-wrapper" style={{top: 0, left: visibleAreaEdge + 'px', position: 'absolute', width: '947px', height: '760px', overflow: 'hidden'}}>
              <Carousel ref="carouselRef" data={slideshowData} globalState={globalState} fullscreen={false} position={['0', '0']} size={["992px", "800px"]} state={this.state} />
            </div>
            {data.bgVideo ?
              <div className="video-wrapper" style={{ position: 'absolute', left: 0, top: 0 }}>
                <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
                  poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} fullscreenClass={''} loop={true} ref={div => this.bgVideoRef = div} globalState={globalState} /></div> : <div />
            }
            <img src={globalState.cdnUrl + data.divider} alt="" style={{position: 'absolute', top: 0, left: '470px', height: '770px'}}/>

          </div>


        </div>
        )}
      </>
    );
  }


};

export default ShelfLifePage3
